//library imports
import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";
import { refreshToken, } from "./actions/auth";
import { loadAllFeatures } from "./actions/features";
import { useDispatch } from "react-redux";
import { lazy } from "react";
import { withRouter } from "react-router-dom";

//Routes
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const TherapistPrivateRoute = lazy(() => import("./components/TherapistPrivateRoute"));
const AdminPrivateRoute = lazy(() => import("./components/AdminPrivateRoute"));

//utilities
import Loading from "./components/Utilities/Loading/Loading";

//screens
const AboutUs = lazy(() => import("./screens/AboutUs.js"));
const ProfileScreen = lazy(() => import("./screens/ProfileScreen.js"));
const ForgotPasswordScreen = lazy(() => import("./screens/ForgotPasswordScreen.js"));
const MoodTrackerScreen = lazy(() => import("./screens/afterLoginScreen/MoodTrackerScreen.js"));
const SessionBookingScreen = lazy(() => import("./screens/afterLoginScreen/SessionBookingScreen.js"));
const MyBookingsScreen = lazy(() => import("./screens/afterLoginScreen/MyBookingsScreen.js"));
const MySessionScreen = lazy(() => import("./screens/therapistScreen/MySessionScreen.js"));
const DashboardScreen = lazy(() => import("./screens/afterLoginScreen/DashboardScreen.js"));
const MyTherapistScreen = lazy(() => import("./screens/admin/MyTherapistScreen.js"));
const MyClientScreen = lazy(() => import("./screens/admin/MyClientScreen.js"));
const SingleClientScreen = lazy(() => import("./screens/admin/SingleClientScreen.js"));
const HRDashboard = lazy(() => import("./screens/admin/HRDashboard"));
const OnBoardNewClient = lazy(() => import("./screens/admin/OnBoardNewClient"));
const OnBoardNewTherapist = lazy(() => import("./screens/admin/OnBoardNewTherapist"));
const SingleTherapistScreen = lazy(() => import("./screens/admin/SingleTherapistScreen"));
const TherapistUpdateScreen = lazy(() => import("./screens/admin/TherapistUpdateScreen"));
const ClientUpdateScreen = lazy(() => import("./screens/admin/ClientUpdateScreen"));
const AllQuizzesScreen = lazy(() => import("./screens/quiz/AllQuizzesScreen"));
const SingleQuizScreen = lazy(() => import("./screens/quiz/SingleQuizScreen"));
const PaymentRoute = lazy(() => import("./screens/afterLoginScreen/PaymentRoute"));
const ViewSessionLevelMonth = lazy(() => import("./screens/admin/ViewSessionLevelMonth"));
const ViewSessionLevelTherapist = lazy(() => import("./screens/admin/ViewSessionLevelTherapist"));
const UserAnalyticsScreen = lazy(() => import("./screens/admin/UserAnalyticsScreen"));
const FeedbackInfo = lazy(() => import("./screens/admin/FeedbackInfo"));
const PageNotFound = lazy(() => import("./screens/PageNotFound/PageNotFound.js"));
const LoginPageCheck = lazy(() => import("./Containers/Login"));
const RegistrationPageCheck = lazy(() => import("./Containers/Registration"));
const AssessmentQuiz = lazy(() => import("./Containers/Assessment Quiz/index.js"));
const EmployeeWellness = lazy(() => import("./screens/landing-pages/employeeWellness/EmployeeWellness.js"));
const PaymentPlan = lazy(() => import("./Containers/Payment Plan/index.js"));
const StartChallenge = lazy(() => import("./screens/StartChallenge.js"));
const StartChallenge1 = lazy(() => import("./screens/StartChallenge1"));
const StartChallenge2 = lazy(() => import("./screens/StartChallenge2"));
const SessionMeeting = lazy(() => import("./components/SessionMeeting"));
// const Testing1 = lazy(() => import("./components/Testing1"));
const Addquestion = lazy(() => import("./components/Addquestion"));

//css before
//import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "components/index.css";
import { logoutUser, removeAvailableFeatures, removeUserInfo, removeUserToken, setAvailableFeatures, setUserInfo, setUserToken } from "store/actions";

const UserDashboard = lazy(() => import("./components/Dashboard/UserDashboard"));
const TherapistDashboard = lazy(() => import("./components/Dashboard/TherapistDashboard"));
const DashboardScreenUI = lazy(() => import("./screens/afterLoginScreen/DashboardScreenUI"));
const BookingSelection = lazy(() => import("./Containers/Booking Selection/index.js"));
const RescheduleSession = lazy(() => import("./Containers/Reschedule/index.js"));
const Home = lazy(() => import("ContainersUpdated/Home"));
const PulseCheckIn = lazy(() => import("./ContainersUpdated/Pulse Check-in"));
const EmployeeEngagement = lazy(() => import("./ContainersUpdated/Employee Engagement"));
const PredictBurnout = lazy(() => import("./ContainersUpdated/Predict Burnout"));
const OverallWellness = lazy(() => import("./ContainersUpdated/Overall wellness"));
const CorporateMentalHealthPrograms = lazy(() =>
  import("screens/landing-pages/corporateMentalHealthPrograms/corporateMentalHealthPrograms")
);
const EmployeeMentalHealth = lazy(() => import("screens/landing-pages/employeeMentalHealth/employeeMentalHealth"));
const CorporateWellness = lazy(() => import("screens/landing-pages/corporateWellness/corporateWellness"));
const CommunityPopup = lazy(() => import("components/CommunityPopup/CommunityPopup"));

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  //runs only on initial render
  useEffect(() => {
    refreshTheExistToken();
    // eslint-disable-next-line
    const routesToShowPopup = [
      "/",
      "/overall-wellness",
      "/employee-engagement",
      "/pulse-check-in",
      "/predict-burnout",
    ];
    
    const popupShown = sessionStorage.getItem("popupShown");
    
    if (!popupShown && routesToShowPopup.includes(location.pathname)) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem("popupShown", "true");
      }, 15000);
      return () => clearTimeout(timeout);
    }
  }, []);
  
  const handleContinue = () => {
    setShowPopup(false);
  };
  
  const handleJoin = () => {
    setShowPopup(false);
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdpxs63Wn9o_Hjy3NAqhwCFU_WLCAajV6RvrSzBt09FjEzpLQ/viewform",
      "_blank"
    );
  };
  
  //gets the logged in user access by renewing access token
  const refreshTheExistToken = async () => {
    try {
      const res = await refreshToken();
      const { userToken, userInfo } = res.data;
      window.localStorage.setItem("auth", JSON.stringify(res.data));
      dispatch(setUserToken(userToken));
      userInfo.isDemoUser=false;
      if(userInfo.email.substring(userInfo.email.indexOf("@") + 1) === "goodlives.demo"){
        userInfo.isDemoUser=true;
      }

      dispatch(setUserInfo(userInfo));

      const allFeatures = await loadAllFeatures(userToken);
      dispatch(setAvailableFeatures(allFeatures.data));
      
      return setLoading(false);
    } catch (e) {
      logoutUser(dispatch);
      window.localStorage.removeItem("auth");
      setLoading(false);
    }
  };
  // refreshTheExistToken();
  // useLayoutEffect(() => refreshTheExistToken(), [])
  
  function GetURLId() {
    let { id } = useParams();
    window.location.href = "http://goodlives.in/.well-known/acme-challenge/" + id;
    return null;
  }
  
  function CorporatePageRedirect() {
    window.location.href = "/overall-wellness";
    return null;
  }

  return (
    <>
      {/* <Navbar /> */}
      {loading ? (
        <Loading show={true} />
      ) : (
        <Suspense fallback={<Loading show={true} /> } >
          <Loading /> 
          <Switch>
            <Route exact path="/.well-known/acme-challenge/:id" children={<GetURLId />} />
            <Route path="/" exact component={Home} />
            <Route path="/employee-wellness" exact component={EmployeeWellness} />
            <Route path="/corporate-wellness" exact component={CorporateWellness} />
            <Route path="/corporate-mental-health-programs" exact component={CorporateMentalHealthPrograms} />
            <Route path="/employee-mental-health" exact component={EmployeeMentalHealth} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/session/:meetid/" exact component={SessionMeeting} />
            {/* <Route path="/testing1" exact component={Testing1} />
            <Route path="/addquestion" exact component={Addquestion} /> */}
            <Route path="/pulse-check-in" component={PulseCheckIn} />
            <Route path="/employee-engagement" component={EmployeeEngagement} />
            <Route path="/predict-burnout" component={PredictBurnout} />
            <Route path="/overall-wellness" component={OverallWellness} />
            <Route path="/signin" exact component={LoginPageCheck} />
            <Route exact path="/signup" component={RegistrationPageCheck} />
            <Route exact path="/corporates" children={<CorporatePageRedirect />} />
            <Route path="/forgotpassword" exact component={ForgotPasswordScreen} />
            <PrivateRoute path="/workshops/:workshopId" exact component={DashboardScreen} />
            <PrivateRoute path="/profile" exact component={ProfileScreen} />
            <PrivateRoute path="/moodtracker" exact feature="Mood Tracker" component={MoodTrackerScreen} />
            {<PrivateRoute path="/quiz-old" exact component={AllQuizzesScreen} />}
            <PrivateRoute path="/quiz/:quizNo" exact component={AssessmentQuiz} />
            <PrivateRoute path="/quiz-old/:quizNo" exact component={SingleQuizScreen} />
            {/* <PrivateRoute path="/mybookings" exact component={MyBookingsScreen} /> */}
            {/* <Route path="/startchallenge" exact component={StartChallenge} />
            <Route path="/startchallenge1" exact component={StartChallenge1} />
            <Route path="/startchallenge2" exact component={StartChallenge2} /> */}
            <PrivateRoute path="/dashboard" exact component={DashboardScreen} />
            <PrivateRoute
              path="/dashboard/overview"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ overview: true }} />}
            />
            <PrivateRoute
              path="/dashboard/mindfulness"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ mindfullness: true }} />}
            />
            <PrivateRoute
              path="/dashboard/assessments"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ assessments: true }} />}
            />
            <PrivateRoute path="/dashboard/assessments/:urlSlug" exact component={AssessmentQuiz} />

            <PrivateRoute
              path="/dashboard/mood-tracker"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ moodTracker: true }} />}
            />
            <PrivateRoute
              path="/dashboard/my-bookings"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ myBookings: true }} />}
            />
            <PrivateRoute
              path="/dashboard/workshops"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ workshops: true }} />}
            />
            <PrivateRoute
              path="/dashboard/wallet"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ wallet: true }} />}
            />
            <PrivateRoute
              path="/dashboard/talk-to-aura"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ aura: true }} />}
            />
            <PrivateRoute
              path="/dashboard/support"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ support: true }} />}
            />

            <PrivateRoute
              path="/appsmith/dashboard/overview"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ overview: true }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/users"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ users: true }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/sessions" 
              exact 
              render={(props) => <DashboardScreen {...props} routeProps={{ sessions: true }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/slack"
              exact
              render={(props) => ( <DashboardScreen {...props} routeProps={{ sessions: true }} /> )}
            />
            <PrivateRoute
              path="/appsmith/dashboard/assessment-score"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ slack: true }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/support"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ support: true }} />}
            />
            <PrivateRoute
              path="/sessionBooking/payment-plans"
              exact
              render={(props) => <PaymentPlan {...props} routeProps={{ categoryId: 1, currency: "INR" }} />}
            />
            <PrivateRoute
              path="/sessionBooking/payment-failure"
              exact
              render={(props) => <PaymentPlan {...props} routeProps={{ errorPayment: true }} />}
            />
            <PrivateRoute path="/dashboardui" exact component={DashboardScreenUI} />
            <Route path="/user-dashboard" exact component={UserDashboard} />
            <Route path="/therapist-dashboard" exact component={TherapistDashboard} />
            {/*<Route exact path='/overview' component={WholeApp} />
              <Route exact path='/c2c' component={WholeAppC2C} />
              <Route exact path='/b2b' component={WholeAppB2B} />*/}
            <PrivateRoute path="/rescheduleSession" exact feature="Therapy Booking" component={RescheduleSession} />
            <PrivateRoute path="/sessionBooking-old" exact feature="Therapy Booking" component={SessionBookingScreen} />
            <PrivateRoute path="/sessionBooking" exact feature="Therapy Booking" component={BookingSelection} />
            <PrivateRoute path="/bookingDetails" exact component={PaymentRoute} />
            {/* <TherapistPrivateRoute path="/mysessions" exact component={MySessionScreen} /> */}
            <AdminPrivateRoute path="/ViewSessionLevelMonth" exact component={ViewSessionLevelMonth} />
            <AdminPrivateRoute path="/ViewSessionLevelTherapist" exact component={ViewSessionLevelTherapist} />
            <AdminPrivateRoute path="/feedbackInfo" exact component={FeedbackInfo} />
            <AdminPrivateRoute path="/userAnalytics" exact component={UserAnalyticsScreen} />
            <AdminPrivateRoute path="/mytherapists" exact component={MyTherapistScreen} />
            <AdminPrivateRoute path="/mytherapists/:therapistId" exact component={SingleTherapistScreen} />
            <AdminPrivateRoute path="/mytherapists/update/:therapistId" exact component={TherapistUpdateScreen} />
            <AdminPrivateRoute path="/myclients" exact component={MyClientScreen} />
            <AdminPrivateRoute path="/myclients/:clientId" exact component={SingleClientScreen} />
            <AdminPrivateRoute path="/myclients/update/:clientId" exact component={ClientUpdateScreen} />
            <AdminPrivateRoute path="/myclients/dashboard/:clientId" exact component={HRDashboard} />
            <AdminPrivateRoute path="/onboardnewclient" exact component={OnBoardNewClient} />
            <AdminPrivateRoute path="/onboardnewtherapist" exact component={OnBoardNewTherapist} />
            <Route component={PageNotFound} />
          </Switch>
          <CommunityPopup
            isOpen={showPopup}
            onClose={handleContinue}
            onJoin={handleJoin}
          />
        </Suspense>
      )}
    </>
  );
};

export default withRouter(App);
